import { EmptySearchResult } from '@shopify/polaris';
import { FC, useCallback, useEffect, useState } from 'react';
import { IProduct } from '../../interfaces/IProduct';
import { AppButton } from '../button/Button';
import { PageAwarePagination } from '../pagination/page-aware-pagination';
import { AppCard } from '../structure/card/card';
import { AppStack } from '../structure/stack/Stack';
import { AppTextStyle } from '../text/text-style/TextStyle';
import { ProductCard } from './product-card/product-card';
import { ProductGridBaseProps } from './product-list.interfaces';
import { LoadingWrapper } from '../loading-wrapper/loading-wrapper';
import { EmptyLayout } from '../empty-layout/empty-layout';

import './product-grid.scss';

type IProductsGridProps<T extends IProduct> = ProductGridBaseProps<T> & {
  title: string;
  filter: FC;
  addToImportList: (productIds: string[]) => void;
  handleCreateRequest: (supplierId: string) => void;
};

export function ProductGrid<T extends IProduct>({
  title,
  products,
  fetching,
  onPageChange,
  addToImportList,
  handleCreateRequest,
  totalPageCount,
  filter: ProductFilter,
}: IProductsGridProps<T>) {
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);

  const handlePageChange = useCallback(
    (page: number) => {
      onPageChange(page);
      setSelectedProducts([]);
    },
    [onPageChange],
  );

  useEffect(() => {
    setSelectedProducts([]);
  }, [fetching]);

  const handleSelectedProducts = useCallback(
    (checked: boolean, productId: string) =>
      setSelectedProducts((selected) => {
        if (selected.includes(productId)) return selected.filter((p) => p !== productId);
        return [...selected, productId];
      }),
    [],
  );

  const handleAddToImportList = useCallback(() => {
    addToImportList(selectedProducts);
  }, [selectedProducts, addToImportList]);

  return (
    <div key={title} className="product-grid">
      <AppCard title={title}>
        <ProductFilter />
      </AppCard>
      <div className="product-grid--selected">
        {selectedProducts.length !== 0 && (
          <AppStack alignment="center">
            <AppTextStyle variation="strong">
              {selectedProducts.length} Product Selected
            </AppTextStyle>
            <AppButton onClick={handleAddToImportList}>Add to Import List</AppButton>
          </AppStack>
        )}
      </div>
      <LoadingWrapper loading={fetching} className="product-grid__spinner">
        <EmptyLayout
          empty={!products.length}
          emptyComponent={
            <EmptySearchResult
              title={'No products found'}
              description={"If you're using search or a filter, try changing it"}
              withIllustration
            />
          }
        >
          <div className="product-grid__view">
            {products.map((product) => (
              <ProductCard
                key={product.id}
                product={product}
                selectedProducts={selectedProducts}
                onSelectionHandler={handleSelectedProducts}
                handleCreateRequest={handleCreateRequest}
              />
            ))}
          </div>
        </EmptyLayout>
      </LoadingWrapper>
      <PageAwarePagination totalPageCount={totalPageCount} onPageChange={handlePageChange} />
    </div>
  );
}
