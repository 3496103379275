import { SALE_PRICE_OPTION } from './IPreferences';
import { PRODUCT_CATEGORY } from '../../core/constants/product.constants';
import { POSTAGE_SETUP } from '../../shopify-supplier/constants/preferences.constants';
import { ILocation } from '../../shopify-supplier/interfaces/ILocation';
import { IShippingPreferences } from '../../shopify-supplier/interfaces/IPreferences';
import { PRODUCT_GROUP_CONNECTION_TYPE } from '../../core/interfaces/IProduct';

export enum MERCHANDISE_OPTIONS {
  MARKETPLACE = 'marketplace',
  EMAIL = 'email',
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  YOUTUBE = 'youTube',
  SEO = 'seo',
  CPC_CPA = 'cpc/cpa',
  BLOG = 'blog',
  OTHER = 'other',
}

export enum MARKETPLACE_ACCESS_STATUS {
  APPROVED = 'approved',
  REQUESTED = 'requested',
  NONE = 'none',
}
export interface IRetailerVariant {
  id: string;
  title: string;
  sku: string;
  qty: number;
  price: number;
  image?: string;
  tracked?: boolean;
  inventoryPolicy?: 'deny' | 'continue';
}

export interface IMarketplaceVariant extends IRetailerVariant {
  MSRP: number;
  priceForRetailers: number;
  weight?: number;
  grams?: number;
  weightUnit: 'g' | 'kg' | 'oz' | 'lb';
  price: number;
  tracked: boolean;
  inventoryPolicy: 'deny' | 'continue';
  margin: number;
}

export interface IInventoryVariant extends IRetailerVariant {
  managed: boolean;
  connected: boolean;
  shopifyId: number;
  shopifyProductId: number;
  MSRP?: number;
  cost?: number;
  supplierName?: string;
  manuallySetPrice: boolean;
  defaultMarkup?: number; // percent
  priceSyncOption: string;
  useCompanyDefault: boolean;
  salePriceOption: SALE_PRICE_OPTION;
  retailerSalePriceOption: SALE_PRICE_OPTION;
  retailerDefaultMarkup: number;
  tracked?: boolean;
  inventoryPolicy?: 'deny' | 'continue';
  adding: boolean;
}

export interface IInventoryVariantFromApi extends IInventoryVariant {}

export interface IMarketplaceDetailsVariant extends Record<string, unknown> {
  id: string;
  groupId: string;
  productGroupTitle: string;
  title?: string;
  vendor: string;
  MSRP: number;
  price: number;
  priceForRetailers: number;
  qty: number;
  sku: string;
  image?: string;
  connectedProducts: number;
  supplierId: string;
  supplierName: string;
  tracked?: boolean;
  inventoryPolicy?: 'deny' | 'continue';
  margin?: number;

  weight?: number;
  grams?: number;
  weightUnit: 'g' | 'kg' | 'oz' | 'lb';

  connectedProductsIds?: {
    retailerProductId?: number;
    retailerProductGroup?: string;
  }[];
}

export interface IVariantFromImportList {
  id: string;
  title: string;
  image?: string;
  options: string[];
  sku: string;
  price: number;
  compareAtPrice: number | null;
  MSRP: number | null;
  priceForRetailers: number | null;
  quantityAvailable: number;
  manuallySetPrice: boolean;
  tracked: boolean;
  inventoryPolicy: 'deny' | 'continue';
  margin: number;
}

export interface IMarketplaceBuildVariant extends IMarketplaceDetailsVariant {
  freeShipping?: boolean;
  accessStatus: MARKETPLACE_ACCESS_STATUS;
}

export interface IVariantList {
  products: IMarketplaceBuildVariant[];
  total: number;
}

export interface IConnectedProducts {
  id: string;
  image?: string;
  title: string;
  vendor: string;
  sku: string;
  retailerProductId: number;
  retailerProductGroup: string;
  connectionType: PRODUCT_GROUP_CONNECTION_TYPE;
}

export interface IMarketplaceProductDetails {
  id: string;
  supplierName: string;
  title: string;
  description: string | null;
  tags: string;
  vendor: string;
  productType: string;
  productCategory: PRODUCT_CATEGORY;
  supplierId: string;
  isAdded?: boolean;
  images: ISupplierProductImage[];
  variants: IMarketplaceDetailsVariant[];
  postageSetup: POSTAGE_SETUP;
  location: ILocation;
  shipping: IShippingPreferences;
  accessStatus: MARKETPLACE_ACCESS_STATUS;
}

interface ISupplierProductImage {
  id: number;
  position: number;
  productId: number;
  src: string;
  width: number;
  height: number;
}

export interface IRetailerVariant extends Record<string, unknown> {
  id: string;
  image?: string;
  title: string;
  productGroupTitle: string;
  vendor?: string;
  sku: string;
  supplierName?: string;
  supplierSku?: string;
  price: number;
  compareAtPrice: number | null;
  qty: number;
  connected: boolean;
  shopifyId: number;
  shopifyProductId: number;
  shopifyVariantId: number;
}
