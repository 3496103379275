import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { CartSaleIcon } from '@shopify/polaris-icons';
import { AppModal } from '../../../../core/components/overlays/modal/modal';
import { ISuppliersDetails, marketplaceApi } from '../../../api/marketplace.api';
import { IRequestApprovalType, productsApi } from '../../../api/products.api';
import { LoadingLayout } from '../../../../core/layouts/loading-layout/loading-layout';
import { AppThumbnail } from '../../../../core/components/image-containers/thumbnail/Thumbnail';
import { AppButton } from '../../../../core/components/button/Button';
import {
  setMarketplaceProductListPropertyFilterAction,
  setMarketplaceProductListSupplierFilterAction,
} from '../../../redux/modules/marketplace/marketplace.actions';
import { ReactComponent as CannabisIcon } from '../../../../core/images/cannabis.svg';
import { AppDisplayText } from '../../../../core/components/text/display-text/display-text';
import { AppTextStyle } from '../../../../core/components/text/text-style/TextStyle';
import { BUSINESS_TYPE } from '../../../../shopify-supplier/interfaces/IPreferences';
import { AppIcon } from '../../../../core/components/icon/icon';
import { PRODUCT_CATEGORY } from '../../../../core/constants/product.constants';
import { MERCHANDISE_OPTIONS } from '../../../interfaces/IProduct';
import { AppControlledCheckbox } from '../../../../core/components/forms/controlled-checkbox/controlled-checkbox';
import { AppControlledTextField } from '../../../../core/components/forms/controlled-text-field/controlled-text-field';
import { AppButtonGroup } from '../../../../core/components/button-group/button-group';
import { AppBanner } from '../../../../core/components/feedback-indicators/banner/banner';
import { validation } from '../../../../core/helpers/validations.helper';

import './product-request-modal.scss';

const TEXT_FIELD_LINES = 3;

const merchandiseOptions: { value: MERCHANDISE_OPTIONS; label: string }[] = [
  { value: MERCHANDISE_OPTIONS.MARKETPLACE, label: 'Marketplaces like Amazon, eBay, Etsy, etc.' },
  { value: MERCHANDISE_OPTIONS.EMAIL, label: 'Email Marketing Lists' },
  { value: MERCHANDISE_OPTIONS.FACEBOOK, label: 'Facebook' },
  { value: MERCHANDISE_OPTIONS.INSTAGRAM, label: 'Instagram' },
  { value: MERCHANDISE_OPTIONS.YOUTUBE, label: 'YouTube' },
  { value: MERCHANDISE_OPTIONS.SEO, label: 'SEO' },
  { value: MERCHANDISE_OPTIONS.CPC_CPA, label: 'Paid CPC/CPA Advertising' },
  { value: MERCHANDISE_OPTIONS.BLOG, label: 'Personal Website/Blog' },
  { value: MERCHANDISE_OPTIONS.OTHER, label: 'Other' },
];

interface IRequestApprovalModal {
  open: boolean;
  supplierId: string;
  handleClose: () => void;
  handleCreateRequest?: (supplierId: string) => void;
}

export function ProductRequestModal({
  open,
  supplierId,
  handleClose,
  handleCreateRequest,
}: IRequestApprovalModal) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isOpenBanner, setIsOpenBanner] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [supplier, setSupplier] = useState<Omit<ISuppliersDetails, 'bio' | 'state'> | null>(null);

  useEffect(() => {
    marketplaceApi
      .getSupplierInfo(supplierId)
      .then((data) => {
        setSupplier(data);
      })
      .finally(() => setIsLoading(false));
  }, [supplierId]);

  const initialValues = useMemo(
    () => ({
      merchandiseOptions: merchandiseOptions.reduce<Record<MERCHANDISE_OPTIONS, boolean>>(
        (acc, mO) => ({
          ...acc,
          [mO.value]: false,
        }),
        {} as Record<MERCHANDISE_OPTIONS, boolean>,
      ),
      brands: '',
      message: '',
    }),
    [],
  );

  const handleSubmit = useCallback(
    (body: Omit<IRequestApprovalType, 'supplierId'>) => {
      setIsLoadingButton(true);
      productsApi
        .sendProductsRequest({ ...body, supplierId })
        .then(() => {
          handleCreateRequest?.(supplierId);
        })
        .finally(() => setIsLoadingButton(true));
    },
    [supplierId, handleCreateRequest],
  );

  const handleViewProducts = useCallback(
    (name) => {
      dispatch(setMarketplaceProductListPropertyFilterAction({ properties: [] }));
      dispatch(setMarketplaceProductListSupplierFilterAction({ fulfilledBy: [name] }));
      history.push('/marketplace');
      handleClose();
    },
    [history, dispatch, handleClose],
  );

  if (isLoading || !supplier) return <LoadingLayout text="" />;

  return (
    <AppModal
      open={open}
      title={
        <AppTextStyle variation="strong">{`Request to Sell Products from ${supplier.name}`}</AppTextStyle>
      }
      onClose={handleClose}
    >
      <div
        className="request-product-modal"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="supplier-block">
          <AppThumbnail
            size="medium"
            alt={'logo'}
            source={
              supplier.logo ||
              'https://crowdship-cdn.nyc3.digitaloceanspaces.com/crowdship/logo-crowdship-navy.png'
            }
          />
          <div className="supplier-block__info">
            <AppDisplayText size="small">
              <AppTextStyle variation="strong">{supplier.name}</AppTextStyle>
            </AppDisplayText>
            <AppButton plain onClick={() => handleViewProducts(supplier.name)}>
              View Products
            </AppButton>
          </div>
          <div className="supplier-block__icons">
            {supplier.businessType !== BUSINESS_TYPE.NONE && (
              <div className="supplier__info__option">
                <AppIcon source={CannabisIcon} />
                <AppTextStyle variation="strong">Licensed {supplier.businessType}</AppTextStyle>
              </div>
            )}
            {supplier.category !== PRODUCT_CATEGORY.CANNABIS && (
              <div className="supplier__info__option">
                <AppIcon source={CartSaleIcon} color="highlight" />
                <AppTextStyle variation="strong">Drop Shipping</AppTextStyle>
              </div>
            )}
          </div>
        </div>
        {isOpenBanner && (
          <AppBanner status="info" onDismiss={() => setIsOpenBanner(false)}>
            Retailers that add context to their request get more, faster responses.
          </AppBanner>
        )}
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({ values, submitForm, touched }) => (
            <Form>
              <div className="request-form">
                <AppDisplayText size="small">
                  <AppTextStyle variation="strong">
                    How will you merchandise AFM Glass’s products in your store?{' '}
                  </AppTextStyle>
                </AppDisplayText>
                {Object.keys(values.merchandiseOptions).map((key) => (
                  <AppControlledCheckbox
                    name={`merchandiseOptions.${key}`}
                    label={merchandiseOptions.find((mO) => mO.value === key)?.label}
                  />
                ))}
                {touched.merchandiseOptions &&
                  Object.values(values.merchandiseOptions).every((mO) => !mO) && (
                    <AppTextStyle variation="negative">
                      At least one option should be selected
                    </AppTextStyle>
                  )}
                <AppControlledTextField
                  label={'List some brands you currently carry in your store '}
                  name={'brands'}
                  multiline={TEXT_FIELD_LINES}
                  validate={validation.isNotEmpty}
                />
                <AppControlledTextField
                  label={'Send a message along with your request'}
                  name={'message'}
                  multiline={TEXT_FIELD_LINES}
                  validate={validation.isNotEmpty}
                />
                <AppButtonGroup>
                  <AppButton onClick={handleClose}>Cancel</AppButton>
                  <AppButton onClick={submitForm} loading={isLoadingButton}>
                    Submit Request
                  </AppButton>
                </AppButtonGroup>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </AppModal>
  );
}
