import { MARKETPLACE_ACCESS_STATUS } from '../../../../shopify-retailer/interfaces/IProduct';
import { PendingApprovalButton } from '../approval-button/pending-approval-button/pending-approval-button';
import {
  RequestApprovalButton,
  RequestApprovalButtonType,
} from '../approval-button/request-approval-button/request-approval-button';

type RequestApprovalWrapperProps = {
  accessStatus: MARKETPLACE_ACCESS_STATUS;
} & RequestApprovalButtonType;

export const RequestApprovalWrapper = (
  props: React.PropsWithChildren<RequestApprovalWrapperProps>,
) => {
  const { disabled, accessStatus, supplierId, onlyIcon, children, handleCreateRequest } = props;

  if (accessStatus === MARKETPLACE_ACCESS_STATUS.APPROVED) return <>{children}</>;

  if (accessStatus === MARKETPLACE_ACCESS_STATUS.REQUESTED)
    return <PendingApprovalButton onlyIcon={onlyIcon} />;

  return (
    <RequestApprovalButton
      onlyIcon={onlyIcon}
      disabled={disabled}
      supplierId={supplierId}
      handleCreateRequest={handleCreateRequest}
    />
  );
};
