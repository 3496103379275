import { useIndexResourceState } from '@shopify/polaris';
import { title } from 'process';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppToast } from '../../../../../core/components/feedback-indicators/toast/toast';
import { AppIndexTable } from '../../../../../core/components/index-table/index-table';
import { PageAwarePagination } from '../../../../../core/components/pagination/page-aware-pagination';
import { MarketplaceBuildFilter } from '../../../../../core/components/product-list/table-elements/product-filter/marketplace-build-filter';
import { AppCard } from '../../../../../core/components/structure/card/card';
import { IMarketplaceBuildVariant } from '../../../../interfaces/IProduct';
import { getMarketplaceVariantListAction } from '../../../../redux/modules/marketplace/marketplace.actions';
import { getStatusAction } from '../../../../redux/modules/status/status.actions';
import { BuilderVariantListRow } from './builder-variant-list-row';
import { VariantConnectionModal } from '../../../modals/variant-connection-modal/variant-connection-modal';
import { marketplaceVariantHeadings } from './builder.constants';
import '../variant-list.scss';

type VariantListProps = {
  variants: IMarketplaceBuildVariant[];
  fetching: boolean;
  disableAdding: boolean | null;
  totalPageCount: number;
  onPageChange: (page: number) => void;
  handleCreateRequest: (supplierId: string) => void;
};

export const BuilderVariantList = ({
  variants,
  fetching,
  totalPageCount,
  disableAdding,
  onPageChange,
  handleCreateRequest,
}: VariantListProps) => {
  const dispatch = useDispatch();
  const [variantConnected, setVariantConnected] = useState(false);
  const [errorWhenConnecting, setErrorWhenConnecting] = useState('');
  const [pendingConnect, setPendingConnect] = useState<string | undefined>();

  const handleSuccessConnection = useCallback(() => {
    setVariantConnected(true);
    setPendingConnect(undefined);
    dispatch(getMarketplaceVariantListAction());
    dispatch(getStatusAction());
  }, [dispatch]);

  const connectionModalMarkup = useMemo(() => {
    if (!pendingConnect) return null;
    return (
      <VariantConnectionModal
        crowdshipProduct={pendingConnect}
        onClose={() => setPendingConnect(undefined)}
        onSuccess={handleSuccessConnection}
        onError={(error: string) => {
          setErrorWhenConnecting(error);
          setPendingConnect(undefined);
        }}
      />
    );
  }, [pendingConnect, handleSuccessConnection]);

  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(variants);

  return (
    <div key={title} className="variant-list">
      <AppCard title="Variant list">
        <MarketplaceBuildFilter />
        <AppIndexTable
          resourceName={{ singular: 'variant', plural: 'variants' }}
          headings={marketplaceVariantHeadings}
          itemCount={variants.length}
          onSelectionChange={handleSelectionChange}
          selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
          promotedBulkActions={[{ content: 'Build', disabled: !!disableAdding }]}
          hasMoreItems={totalPageCount > 1}
          loading={fetching}
          selectable={false}
        >
          {variants.map((variant, index) => (
            <BuilderVariantListRow
              variant={variant}
              index={index}
              disableAdding={disableAdding}
              onConnect={setPendingConnect}
              handleCreateRequest={handleCreateRequest}
              selected={selectedResources.includes(variant.id)}
            />
          ))}
        </AppIndexTable>
        <PageAwarePagination totalPageCount={totalPageCount} onPageChange={onPageChange} />
      </AppCard>
      {connectionModalMarkup}
      {variantConnected && (
        <AppToast
          content="Variant successfully connected"
          onDismiss={() => setVariantConnected(false)}
        />
      )}
      {errorWhenConnecting && (
        <AppToast
          content={errorWhenConnecting}
          onDismiss={() => setErrorWhenConnecting('')}
          error
        />
      )}
    </div>
  );
};
