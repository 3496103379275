import { useState } from 'react';
import { ProductRequestModal } from '../../../../../shopify-retailer/components/modals/product-request-modal/product-request-modal';
import { ReactComponent as Lock } from '../../../../images/lock.svg';
import { AppButton } from '../../../button/Button';
import '../approval-button.scss';

export type RequestApprovalButtonType = {
  onlyIcon?: boolean;
  disabled?: boolean;
  supplierId?: string;
  handleCreateRequest?: (supplierId: string) => void;
};

export const RequestApprovalButton = ({
  supplierId,
  disabled,
  onlyIcon,
  handleCreateRequest,
}: RequestApprovalButtonType) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  return (
    <>
      <div
        onClick={(e) => {
          e.stopPropagation();
          setIsOpenModal(true);
        }}
      >
        <AppButton disabled={disabled} id={'approval-button'} icon={<Lock />}>
          {onlyIcon ? '' : 'Request'}
        </AppButton>
      </div>

      {isOpenModal && supplierId && (
        <ProductRequestModal
          open={isOpenModal}
          supplierId={supplierId}
          handleClose={() => setIsOpenModal(false)}
          handleCreateRequest={handleCreateRequest}
        />
      )}
    </>
  );
};
