import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MarketplaceProductGrid } from '../../../../core/components/product-list/marketplace-product-grid';
import { getNumberOfPages } from '../../../../core/helpers/item-list.helper';
import { marketplaceApi } from '../../../api/marketplace.api';
import {
  addMarketplaceProductsToImportListAction,
  hideImportListProductsAddedToastAction,
  setMarketplaceProductListPageFilterAction,
  setMarketplaceProductListPendingStatusAction,
} from '../../../redux/modules/marketplace/marketplace.actions';
import {
  fetchingMarketplaceProductViewSelector,
  getMarketplaceProductsSelector,
  getTotalMarketplaceProductsCountSelector,
} from '../../../redux/modules/marketplace/marketplace.selectors';
import { DuplicateProductsModal } from '../../modals/duplicate-products-modal/duplicate-products-modal';

export const MarketplaceProductGridLayout = () => {
  const dispatch = useDispatch();

  const products = useSelector(getMarketplaceProductsSelector);
  const total = useSelector(getTotalMarketplaceProductsCountSelector);
  const fetching = useSelector(fetchingMarketplaceProductViewSelector);

  const [isOpenDuplicateModal, setIsOpenDuplicateModal] = useState(false);
  const [pendingSelectedProducts, setPendingSelectedProducts] = useState<string[]>([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    if (!fetching) {
      setIsOpenDuplicateModal(false);
    }
  }, [fetching]);

  useEffect(() => {
    return () => {
      dispatch(hideImportListProductsAddedToastAction());
    };
  }, [dispatch]);

  const handlePageChange = useCallback(
    (page: number) => dispatch(setMarketplaceProductListPageFilterAction({ page })),
    [dispatch],
  );

  const handleAddToImportList = useCallback(
    (selectedProducts: string[]) => {
      marketplaceApi
        .checkImportListProducts(selectedProducts)
        .then(({ data }) => {
          if (products.some((p) => selectedProducts.includes(p.id)) && data.isAdded) {
            setPendingSelectedProducts(selectedProducts);
            setIsOpenDuplicateModal(true);
          } else dispatch(addMarketplaceProductsToImportListAction(selectedProducts));
        })
        .catch(console.error);
    },
    [dispatch, products],
  );

  const postDuplicatesProductsToImportList = useCallback(
    (skip: boolean) => {
      dispatch(addMarketplaceProductsToImportListAction(pendingSelectedProducts, skip));
    },
    [dispatch, pendingSelectedProducts],
  );

  const handleCreateRequest = useCallback(
    (supplierId: string) => {
      dispatch(setMarketplaceProductListPendingStatusAction(supplierId));
    },
    [dispatch],
  );

  return (
    <>
      <MarketplaceProductGrid
        products={products}
        fetching={fetching}
        addToImportList={handleAddToImportList}
        totalPageCount={getNumberOfPages(total)}
        handleCreateRequest={handleCreateRequest}
        onPageChange={handlePageChange}
      />
      <DuplicateProductsModal
        open={isOpenDuplicateModal}
        onClose={() => setIsOpenDuplicateModal(false)}
        postDuplicatesProductsToImportList={postDuplicatesProductsToImportList}
      />
    </>
  );
};
