import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppIndexTable } from '../../../../core/components/index-table/index-table';
import { AppModal } from '../../../../core/components/overlays/modal/modal';
import { PageAwarePagination } from '../../../../core/components/pagination/page-aware-pagination';
import { MarketplaceBuildFilter } from '../../../../core/components/product-list/table-elements/product-filter/marketplace-build-filter';
import { getNumberOfPages } from '../../../../core/helpers/item-list.helper';
import {
  getMarketplaceFilterOptionsAction,
  resetMarketplaceVariantListFiltersAction,
  setMarketplaceVariantListLimitFilterAction,
  setMarketplaceVariantListPageFilterAction,
  setMarketplaceVariantListPendingStatusAction,
} from '../../../redux/modules/marketplace/marketplace.actions';
import {
  fetchingMarketplaceVariantViewSelector,
  getMarketplaceVariantsSelector,
  getTotalMarketplaceVariantsCountSelector,
} from '../../../redux/modules/marketplace/marketplace.selectors';
import { BuilderVariantListRow } from '../../containers/variant-list/builder/builder-variant-list-row';
import { builderHeadings } from '../../containers/variant-list/builder/builder.constants';

type InventoryConnectModalProps = {
  open: boolean;
  connecting: boolean;
  onClose: () => void;
  onConnect: (id: string) => void;
};

export const InventoryConnectModal = ({
  open,
  connecting,
  onClose,
  onConnect,
}: InventoryConnectModalProps) => {
  const productsPerPage = 5;
  const dispatch = useDispatch();
  const variants = useSelector(getMarketplaceVariantsSelector);
  const fetching = useSelector(fetchingMarketplaceVariantViewSelector);
  const total = useSelector(getTotalMarketplaceVariantsCountSelector);

  useEffect(() => {
    dispatch(getMarketplaceFilterOptionsAction());
    dispatch(setMarketplaceVariantListLimitFilterAction({ limit: productsPerPage }));
    return () => {
      dispatch(resetMarketplaceVariantListFiltersAction());
    };
  }, [dispatch]);

  const handleCreateRequest = useCallback(
    (supplierId: string) => {
      dispatch(setMarketplaceVariantListPendingStatusAction(supplierId));
    },
    [dispatch],
  );

  return (
    <AppModal open={open} onClose={onClose} title="Select a variant to connect" large>
      <MarketplaceBuildFilter />
      <AppIndexTable
        selectable={false}
        resourceName={{ singular: 'variant', plural: 'variants' }}
        headings={builderHeadings}
        itemCount={total}
        loading={fetching}
      >
        {variants.map((variant, index) => (
          <BuilderVariantListRow
            variant={variant}
            index={index}
            onConnect={onConnect}
            handleCreateRequest={handleCreateRequest}
            disableAdding={connecting}
            disableCurrentConnectionsButton
          />
        ))}
      </AppIndexTable>
      <PageAwarePagination
        totalPageCount={getNumberOfPages(total, productsPerPage)}
        onPageChange={(page) => dispatch(setMarketplaceVariantListPageFilterAction({ page }))}
      />
    </AppModal>
  );
};
