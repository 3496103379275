import { axiosInstance } from '../../core/api/base.api';
import { PRODUCT_CATEGORY } from '../../core/constants/product.constants';
import { Paginated } from '../../core/helpers/generic.helper';
import { IOption } from '../../core/interfaces/IOption';
import { IMarketplaceProduct, IProductList } from '../../core/interfaces/IProduct';
import {
  IMarketplaceBuildFilter,
  IMarketplaceImportFilter,
  IRetailerProductsFilters,
  ISuppliersInfoFilters,
} from '../../core/interfaces/IProductFilter';
import { BUSINESS_TYPE } from '../../shopify-supplier/interfaces/IPreferences';
import {
  IConnectedProducts,
  IMarketplaceProductDetails,
  IMarketplaceVariant,
  IVariantList,
  IRetailerVariant,
} from '../interfaces/IProduct';
import { IPostProductsResponse } from './inventory.api';

class MarketplaceApi {
  private backend = process.env.REACT_APP_BACK_END_URL as string;
  private shopifyPath = '/api/retailer/shopify/products/marketplace';
  private retailerPath = '/api/retailer/products/marketplace';

  async getProducts(filter: Paginated<IMarketplaceImportFilter>) {
    const { query, vendors, fulfilledBy, types, properties, shipsFrom, ...rest } = filter;
    return await axiosInstance.get<IProductList<IMarketplaceProduct>>(
      `${this.backend}${this.retailerPath}/`,
      {
        params: {
          title: query || undefined,
          vendors: vendors?.length ? vendors.join(',') : undefined,
          fulfilledBy: fulfilledBy?.length ? fulfilledBy.join(',') : undefined,
          types: types?.length ? types.join(',') : undefined,
          properties: properties?.length ? properties.join(',') : undefined,
          shipsFrom: shipsFrom?.length ? shipsFrom.join(',') : undefined,
          ...rest,
        },
      },
    );
  }

  async addMarketplaceProductsToInventory(productIds: string[]) {
    return await axiosInstance.post<IPostProductsResponse>(
      `${this.backend}${this.shopifyPath}/inventory`,
      {
        productIds,
      },
    );
  }

  async addMarketplaceProductsToImportList(productIds: string[], skipDuplicates: boolean) {
    return axiosInstance.post<IPostProductsResponse>(
      `${this.backend}${this.retailerPath}/import-list`,
      {
        productIds,
        skipDuplicates,
      },
    );
  }

  async checkImportListProducts(productIds: string[]) {
    return axiosInstance.post<{ isAdded: boolean }>(
      `${this.backend}${this.retailerPath}/import-list/added`,
      {
        productIds,
      },
    );
  }

  async addAllMarketplaceProductsToImportList(
    filter: IMarketplaceImportFilter & { skipDuplicates: boolean },
  ) {
    const { query, vendors, fulfilledBy, types, properties, ...rest } = filter;
    return axiosInstance.post<IPostProductsResponse>(
      `${this.backend}${this.retailerPath}/import-list/all`,
      {
        title: query || undefined,
        vendors: vendors?.length ? vendors : undefined,
        fulfilledBy: fulfilledBy?.length ? fulfilledBy : undefined,
        types: types?.length ? types : undefined,
        properties: properties?.length ? properties : undefined,
        ...rest,
      },
    );
  }

  async getVariants(filter: Paginated<IMarketplaceBuildFilter>) {
    const { query, fulfilledBy, types, vendors, ...rest } = filter;
    return await axiosInstance.get<IVariantList>(`${this.backend}${this.retailerPath}/variants`, {
      params: {
        title: query || undefined,
        fulfilledBy: fulfilledBy?.length ? fulfilledBy.join(',') : undefined,
        types: types?.length ? types.join(',') : undefined,
        vendors: vendors?.length ? vendors.join(',') : undefined,
        ...rest,
      },
    });
  }

  async getConnectedProducts(crowdshipProductId: string) {
    return await axiosInstance.get<IConnectedProducts>(
      `${this.backend}${this.shopifyPath}/variants/connected-products/${crowdshipProductId}`,
    );
  }

  async disconnectVariant(variantId: string) {
    return await axiosInstance.put<{ queued: true }>(
      `${this.backend}${this.retailerPath}/variants/connected-products/${variantId}`,
    );
  }

  async getMarketplaceProductDetails(productId: string) {
    return await axiosInstance.get<IMarketplaceProductDetails>(
      `${this.backend}${this.retailerPath}/${productId}`,
    );
  }

  async getRetailerVariants(filters: Paginated<IRetailerProductsFilters>) {
    const { query, fulfilledBy, types, vendors, ...rest } = filters;
    return axiosInstance.get<{ products: IRetailerVariant[]; count: number }>(
      `${this.backend}${this.shopifyPath}/variants/connect`,
      {
        params: {
          title: query || undefined,
          fulfilledBy: fulfilledBy?.length ? fulfilledBy.join(',') : undefined,
          types: types?.length ? types.join(',') : undefined,
          vendors: vendors?.length ? vendors.join(',') : undefined,
          ...rest,
        },
      },
    );
  }

  async connectVariant(retailerProduct: string, crowdshipProduct: string) {
    return await axiosInstance.post<{ success: true } | { notFound: true }>(
      `${this.backend}${this.retailerPath}/variants/connect`,
      { retailerProduct, crowdshipProduct },
    );
  }

  async getSupplierInfo(supplierId: string) {
    const response = await axiosInstance.get<Omit<ISuppliersDetails, 'bio' | 'state'>>(
      `${this.backend}${this.retailerPath}/supplier`,
      {
        params: {
          supplierId,
        },
      },
    );

    return response.data;
  }

  async getVendors() {
    return await axiosInstance.get<IOption[]>(`${this.backend}${this.retailerPath}/vendors`);
  }

  async getTypes() {
    return await axiosInstance.get<IOption[]>(`${this.backend}${this.retailerPath}/types`);
  }

  async getSuppliers() {
    return await axiosInstance.get<IOption[]>(`${this.backend}${this.retailerPath}/suppliers`);
  }

  async getShipsFrom() {
    return await axiosInstance.get<IOption[]>(`${this.backend}${this.retailerPath}/ships-from`);
  }

  async getProductVariants(productId: string) {
    return await axiosInstance.get<IMarketplaceVariant[]>(
      `${this.backend}${this.retailerPath}/${productId}/variants`,
    );
  }

  async getSuppliersList(filter: Paginated<ISuppliersInfoFilters>) {
    const { title, productCategory, limit, page } = filter;

    return await axiosInstance.get<{ result: ISuppliersDetails[]; count: number }>(
      `${this.backend}${this.retailerPath}/suppliers-info`,
      {
        params: {
          title,
          productCategory,
          limit,
          page,
        },
      },
    );
  }
}

export const marketplaceApi = new MarketplaceApi();

export interface ISuppliersDetails {
  _id: string;
  name: string;
  logo: string;
  bio: string;
  category: PRODUCT_CATEGORY;
  businessType: BUSINESS_TYPE;
  state: string;
  country: string;
}

export interface ISupplierProductImage {
  id: number;
  position: number;
  productId: number;
  src: string;
  width: number;
  height: number;
}
