import { ReactComponent as Clock } from '../../../../images/clock.svg';
import { AppButton } from '../../../button/Button';

import '../approval-button.scss';

export type PendingApprovalButtonType = {
  onlyIcon?: boolean;
};

export const PendingApprovalButton = ({ onlyIcon }: PendingApprovalButtonType) => {
  return (
    <AppButton disabled={true} id={'approval-button'} icon={<Clock />}>
      {onlyIcon ? '' : 'Pending'}
    </AppButton>
  );
};
