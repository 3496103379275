import { createAction } from 'typesafe-actions';
import { IProductList, IMarketplaceProduct } from '../../../../../core/interfaces/IProduct';
import { IMarketplaceVariant } from '../../../../interfaces/IProduct';
import { ACTIONS_CONSTANTS } from '../../../actions-constants';

export const getMarketplaceProductListAction = createAction(
  ACTIONS_CONSTANTS.GET_MARKETPLACE_PRODUCT_LIST,
)();

export const setMarketplaceProductListAction = createAction(
  ACTIONS_CONSTANTS.SET_MARKETPLACE_PRODUCT_LIST,
  (list: IProductList<IMarketplaceProduct>) => list,
)();

export const setMarketplaceProductListPendingStatusAction = createAction(
  ACTIONS_CONSTANTS.SET_MARKETPLACE_PRODUCT_LIST_PENDING_STATUS,
  (supplierId: string) => supplierId,
)();

export const getMarketplaceProductVariantsAction = createAction(
  ACTIONS_CONSTANTS.GET_MARKETPLACE_PRODUCT_VARIANTS,
  (productId: string) => productId,
)();

export const setMarketplaceProductVariantsAction = createAction(
  ACTIONS_CONSTANTS.SET_MARKETPLACE_PRODUCT_VARIANTS,
  (productId: string, variants: IMarketplaceVariant[]) => ({ productId, variants }),
)();

export const addMarketplaceProductsToInventoryAction = createAction(
  ACTIONS_CONSTANTS.POST_PRODUCT_FROM_MARKETPLACE_TO_INVENTORY,
  (productIds: string[]) => productIds,
)();

export const addMarketplaceProductsToImportListAction = createAction(
  ACTIONS_CONSTANTS.POST_PRODUCTS_TO_IMPORT_LIST,
  (productIds: string[], skipDuplicates: boolean = false) => ({ productIds, skipDuplicates }),
)();

export const addAllMarketplaceProductsToImportListAction = createAction(
  ACTIONS_CONSTANTS.POST_ALL_PRODUCTS_TO_IMPORT_LIST,
  (skipDuplicates: boolean) => skipDuplicates,
)();

export const showImportListProductsAddedToastAction = createAction(
  ACTIONS_CONSTANTS.SHOW_IMPORT_LIST_PRODUCTS_ADDED_TOAST,
)();

export const hideImportListProductsAddedToastAction = createAction(
  ACTIONS_CONSTANTS.HIDE_IMPORT_LIST_PRODUCTS_ADDED_TOAST,
)();

export const setMarketplaceProductListFetchingAction = createAction(
  ACTIONS_CONSTANTS.SET_MARKETPLACE_PRODUCT_LIST_FETCHING,
  (fetching: boolean) => fetching,
)();
