import { QuestionCircleIcon } from '@shopify/polaris-icons';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AppDataTable } from '../../../../../core/components/data-table/DataTable';
import { AppControlledSelect } from '../../../../../core/components/forms/controlled-select/controlled-select';
import { AppControlledTagField } from '../../../../../core/components/forms/controlled-tag-field/controlled-tag-field';
import { AppControlledTextField } from '../../../../../core/components/forms/controlled-text-field/controlled-text-field';
import { AppIcon } from '../../../../../core/components/icon/icon';
import { AppLink } from '../../../../../core/components/link/link';
import { AppList } from '../../../../../core/components/list/list';
import { AppTooltip } from '../../../../../core/components/overlays/tooltip/tooltip';
import { AppCard } from '../../../../../core/components/structure/card/card';
import { AppLayoutAnnotatedSection } from '../../../../../core/components/structure/layout/layout-annotated-section';
import { AppTextContainer } from '../../../../../core/components/text-container/text-container';
import { AppTextStyle } from '../../../../../core/components/text/text-style/TextStyle';
import { validation } from '../../../../../core/helpers/validations.helper';
import { SUPPLIER_ORDER_NAME_ORIGIN } from '../../../../constants/preferences.constants';
import { IPreferences } from '../../../../interfaces/IPreferences';
import { getStatusSelector } from '../../../../redux/modules/status/status.selectors';
import { RetailerAutocompleteField } from '../inputs/retailer-autocomplete-field';
import { MARKETPLACE_ACCESS_STATUS } from '../../../../../shopify-retailer/interfaces/IProduct';

type PrivateNetworkSectionProps = {
  values: IPreferences;
  setFieldValue: (field: string, value: any) => void;
};
export const PrivateNetworkSection = ({ values, setFieldValue }: PrivateNetworkSectionProps) => {
  const { transactionFee } = useSelector(getStatusSelector);
  const settleViaCrowdshipList = [
    'Crowdship will automatically charge the Retailers "Crowdship Wallet" for each order before sending it to your store.',
    'Crowdship will automatically deposit a lump sum to your preferred payout method every 30 days. Quickly reconcile via PDF and CSV Invoices.',
    `A ${transactionFee}% Credit Card Processing fee will be deducted from each order payout.`,
  ];
  const settleOutsideOfCrowdshipList = [
    "Use Crowdship's billing Module to create invoices for this retailer.",
    "Capture payment externally using any method and reconcile using Crowdship's AR Module",
  ];

  const selectedPNRetailers = useMemo(
    () =>
      values.visibility.privateNetwork
        .filter((pN) => pN.marketplaceAccessStatus !== MARKETPLACE_ACCESS_STATUS.REQUESTED)
        .map((rn) => rn.retailer),
    [values.visibility.privateNetwork],
  );

  const handlePrivateNetworkRetailerListUpdate = useCallback(
    (list: { label: string; value: string }[]) => {
      setFieldValue(
        'visibility.privateNetwork',
        list.map((retailer) => ({
          retailer,
          paidViaCrowdship:
            values.visibility.privateNetwork.find((pn) => pn.retailer.value === retailer.value)
              ?.paidViaCrowdship || 'true',
          customerId:
            values.visibility.privateNetwork.find((pn) => pn.retailer.value === retailer.value)
              ?.customerId || '',
          tags:
            values.visibility.privateNetwork.find((pn) => pn.retailer.value === retailer.value)
              ?.tags || '',
          handlingFee:
            values.visibility.privateNetwork.find((pn) => pn.retailer.value === retailer.value)
              ?.handlingFee || '',
          orderPrefix:
            values.visibility.privateNetwork.find((pn) => pn.retailer.value === retailer.value)
              ?.orderPrefix || '',
          marketplaceAccessStatus: MARKETPLACE_ACCESS_STATUS.APPROVED,
        })),
      );
    },
    [setFieldValue, values.visibility.privateNetwork],
  );

  return (
    <div className="retailers-tab">
      <AppLayoutAnnotatedSection
        title="Your Retailers"
        description={
          <>
            <strong>Collect payment via crowdship ({`*${transactionFee}% fee applies`})</strong>
            <AppList>{settleViaCrowdshipList}</AppList>
            <br />
            <strong>Collect payment outside crowdship</strong>
            <AppList>{settleOutsideOfCrowdshipList}</AppList>
          </>
        }
      >
        <AppCard
          sectioned
          title="Retailers added here will be able to import and sell your products"
        >
          <RetailerAutocompleteField
            excludeOptions={values.visibility.marketplace.list}
            selectedOptions={selectedPNRetailers}
            onSelectionChange={handlePrivateNetworkRetailerListUpdate}
          />
        </AppCard>
        <AppCard sectioned title="Private Retailer Payments">
          {!values.visibility.privateNetwork.length && (
            <AppTextContainer>
              <AppTextStyle variation="subdued">
                No retailer's added to private network yet
              </AppTextStyle>
            </AppTextContainer>
          )}
          {!!values.visibility.privateNetwork.length && (
            <AppDataTable
              headings={['Retailer', 'Configuration']}
              columnContentTypes={['text', 'text']}
              rows={values.visibility.privateNetwork
                .filter((pN) => pN.marketplaceAccessStatus !== MARKETPLACE_ACCESS_STATUS.REQUESTED)
                .map((pn, i) => [
                  pn.retailer.label,
                  <>
                    <AppControlledSelect
                      name={`visibility.privateNetwork[${i}].paidViaCrowdship`}
                      options={[
                        {
                          label: `Collect payment via crowdship (*${transactionFee}% fee applies)`,
                          value: 'true',
                        },
                        { label: 'Collect payment outside crowdship', value: 'false' },
                      ]}
                    />

                    <div className="customer-id">
                      <AppControlledTextField
                        name={`visibility.privateNetwork[${i}].customerId`}
                        placeholder="Shopify Customer ID"
                        validate={validation.isOptionalNumber}
                        suffix={
                          <AppTooltip
                            content={`If entered, this customer will be used for orders from ${pn.retailer.label}`}
                            dismissOnMouseOut
                          >
                            <AppIcon source={QuestionCircleIcon} />
                          </AppTooltip>
                        }
                      />
                    </div>
                    <div className="handling-fee">
                      <AppControlledTextField
                        name={`visibility.privateNetwork[${i}].handlingFee`}
                        prefix="$"
                        validate={validation.isDecimal}
                      />
                    </div>
                    {values.order.nameOrigin !== SUPPLIER_ORDER_NAME_ORIGIN.SHOPIFY && (
                      <div className="order-prefix">
                        <AppControlledTextField
                          name={`visibility.privateNetwork[${i}].orderPrefix`}
                          placeholder="Order Number Prefix"
                          suffix={
                            <AppTooltip
                              content={
                                <div>
                                  Enter an order number prefix specific to {pn.retailer.label}. This
                                  will override your{' '}
                                  <AppLink url="/settings?tab=orders">
                                    global order number prefix
                                  </AppLink>{' '}
                                  setting.
                                </div>
                              }
                            >
                              <AppIcon source={QuestionCircleIcon} />
                            </AppTooltip>
                          }
                        />
                      </div>
                    )}
                    <AppControlledTagField
                      name={`visibility.privateNetwork[${i}].tags`}
                      tags={pn.tags}
                      setFieldValue={setFieldValue}
                    />
                  </>,
                ])}
            />
          )}
        </AppCard>
      </AppLayoutAnnotatedSection>
    </div>
  );
};
