import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PRODUCTS_PER_PAGE } from '../../../../core/constants/product.constants';
import { getNumberOfPages } from '../../../../core/helpers/item-list.helper';
import {
  setMarketplaceVariantListLimitFilterAction,
  setMarketplaceVariantListPageFilterAction,
  setMarketplaceVariantListPendingStatusAction,
} from '../../../redux/modules/marketplace/marketplace.actions';
import {
  fetchingMarketplaceVariantViewSelector,
  getMarketplaceVariantsSelector,
  getTotalMarketplaceVariantsCountSelector,
} from '../../../redux/modules/marketplace/marketplace.selectors';
import { getStatusSelector } from '../../../redux/modules/status/status.selectors';
import { BuilderVariantList } from '../../containers/variant-list/builder/builder-variant-list';
import { getProductPreferencesAction } from '../../../redux/modules/preferences/preferences.actions';

export const MarketplaceVariantViewLayout = () => {
  const dispatch = useDispatch();
  const status = useSelector(getStatusSelector);
  const variants = useSelector(getMarketplaceVariantsSelector);
  const total = useSelector(getTotalMarketplaceVariantsCountSelector);
  const fetching = useSelector(fetchingMarketplaceVariantViewSelector);

  useEffect(() => {
    dispatch(setMarketplaceVariantListLimitFilterAction({ limit: PRODUCTS_PER_PAGE }));
    dispatch(getProductPreferencesAction());
  }, [dispatch]);

  const handlePageChange = useCallback(
    (page: number) => {
      dispatch(setMarketplaceVariantListPageFilterAction({ page }));
    },
    [dispatch],
  );

  const disableAdding = useMemo(
    () =>
      status && status.productsLimit !== undefined && status.productsCount >= status.productsLimit,
    [status],
  );

  const handleCreateRequest = useCallback(
    (supplierId: string) => {
      dispatch(setMarketplaceVariantListPendingStatusAction(supplierId));
    },
    [dispatch],
  );

  return (
    <BuilderVariantList
      fetching={fetching}
      onPageChange={handlePageChange}
      totalPageCount={getNumberOfPages(total)}
      handleCreateRequest={handleCreateRequest}
      variants={variants}
      disableAdding={disableAdding}
    />
  );
};
