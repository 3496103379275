import { axiosInstance } from '../../core/api/base.api';
import { MERCHANDISE_OPTIONS } from '../interfaces/IProduct';

class ProductsApi {
  private backend = process.env.REACT_APP_BACK_END_URL as string;
  private retailerPath = '/api/retailer/products';

  async sendProductsRequest(request: IRequestApprovalType) {
    return axiosInstance.post<void>(
      `${this.backend}${this.retailerPath}/request-approval`,
      request,
    );
  }
}

export const productsApi = new ProductsApi();

export type IRequestApprovalType = {
  supplierId: string;
  merchandiseOptions: Record<MERCHANDISE_OPTIONS, boolean>;
  brands: string;
  message: string;
};
