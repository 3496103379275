import { IMarketplaceProduct } from '../../interfaces/IProduct';
import { ProductGrid } from './product-grid';
import { MARKETPLACE_LIST_TITLE } from './product-list.constants';
import { ProductGridBaseProps } from './product-list.interfaces';
import { MarketplaceImportFilter } from './table-elements/product-filter/marketplace-product-filter';

type MarketplaceProductGridProps = ProductGridBaseProps<IMarketplaceProduct> & {
  addToImportList: (productIds: string[]) => void;
  handleCreateRequest: (supplierId: string) => void;
};

export const MarketplaceProductGrid = ({ ...props }: MarketplaceProductGridProps) => (
  <ProductGrid title={MARKETPLACE_LIST_TITLE} filter={MarketplaceImportFilter} {...props} />
);
