import { createReducer } from 'typesafe-actions';
import {
  PRODUCTS_PER_PAGE,
  PRODUCT_CATEGORY,
  PRODUCT_INVENTORY,
  PRODUCT_PROPERTIES,
  RETAILER_PRODUCTS_PER_PAGE,
} from '../../../../core/constants/product.constants';
import { Paginated, PickOptions } from '../../../../core/helpers/generic.helper';
import {
  IMarketplaceProduct,
  IProduct,
  IProductList,
  IVariantMapping,
} from '../../../../core/interfaces/IProduct';
import {
  IConnectionFilters,
  IMarketplaceBuildFilter,
  IMarketplaceFilter,
  IMarketplaceImportFilter,
  IProductListFilter,
  IRetailerProductsFilters,
} from '../../../../core/interfaces/IProductFilter';
import {
  IConnectedProducts,
  IMarketplaceBuildVariant,
  IMarketplaceVariant,
  IRetailerVariant,
  MARKETPLACE_ACCESS_STATUS,
} from '../../../interfaces/IProduct';
import { RootActions } from '../../root.actions';
import {
  decrementVariantConnectedProductsAction,
  hideImportListProductsAddedToastAction,
  removeDisconnectedProductsAction,
  resetMarketplaceProductListFiltersAction,
  resetMarketplaceVariantListFiltersAction,
  setMarketplaceConnectedProductsAction,
  setMarketplaceRetailerProductsFiltersAction,
  resetMarketplaceRetailerProductsFiltersAction,
  setMarketplaceRetailerProductsQueryFilterAction,
  setMarketplaceRetailerProductsVendorFilterAction,
  setMarketplaceRetailerProductsTypeFilterAction,
  setMarketplaceRetailerProductsSupplierFilterAction,
  setMarketplaceRetailerProductsConnectionFilterAction,
  setMarketplaceRetailerProductsPageFilterAction,
  setMarketplaceRetailerProductsLimitFilterAction,
  setMarketplaceFilterOptionsAction,
  setMarketplaceProductListAction,
  setMarketplaceProductListCategoryFilterAction,
  setMarketplaceProductListConnectionFilterAction,
  setMarketplaceProductListFetchingAction,
  setMarketplaceProductListFiltersAction,
  setMarketplaceProductListLimitFilterAction,
  setMarketplaceProductListPageFilterAction,
  setMarketplaceProductListQueryFilterAction,
  setMarketplaceProductListSupplierFilterAction,
  setMarketplaceProductListTypeFilterAction,
  setMarketplaceProductListVendorFilterAction,
  setMarketplaceProductVariantsAction,
  setMarketplaceVariantListAction,
  setMarketplaceVariantListConnectionFilterAction,
  setMarketplaceVariantListFetchingAction,
  setMarketplaceVariantListFiltersAction,
  setMarketplaceVariantListLimitFilterAction,
  setMarketplaceVariantListPageFilterAction,
  setMarketplaceVariantListQueryFilterAction,
  setMarketplaceVariantListSupplierFilterAction,
  setMarketplaceVariantListTypeFilterAction,
  setMarketplaceVariantListVendorFilterAction,
  showImportListProductsAddedToastAction,
  setMarketplaceProductListPropertyFilterAction,
  setMarketplaceVariantListStockFilterAction,
  clearAllMarketplaceProductListFiltersAction,
  setMarketplaceProductListInventoryFilterAction,
  setMarketplaceVariantListInventoryFilterAction,
  setMarketplaceProductListPendingStatusAction,
  setMarketplaceVariantListPendingStatusAction,
  setMarketplaceProductListShipsFromFilterAction,
} from './marketplace.actions';

export interface IProductSectionState<
  P extends IProduct,
  V extends IRetailerVariant,
  F extends IProductListFilter,
> {
  productList: IProductList<P>;
  filter: F;
  variants: IVariantMapping<V>;
  fetching: boolean;
}

interface IMarketplaceReducer {
  options: PickOptions<IMarketplaceFilter>;
  productView: IProductSectionState<
    IMarketplaceProduct,
    IMarketplaceVariant,
    Paginated<IMarketplaceImportFilter>
  >;
  variantView: {
    variantList: {
      products: IMarketplaceBuildVariant[];
      connectedProducts: IConnectedProducts[];
      total: number;
    };
    filter: Paginated<IMarketplaceBuildFilter>;
    retailerProductsFilters: Paginated<IRetailerProductsFilters>;
    fetching: boolean;
  };

  // Flag to show a toast when products are added
  areProductsAddedToImportList: boolean;
}

const defaultConnectionFilters: Paginated<IConnectionFilters> = {
  query: '',
  vendors: [],
  types: [],
  fulfilledBy: [],
  page: 0,
  limit: RETAILER_PRODUCTS_PER_PAGE,
};

const defaultRetailerProductsFilters: Paginated<IRetailerProductsFilters> = {
  query: '',
  vendors: [],
  types: [],
  fulfilledBy: [],
  page: 0,
  limit: RETAILER_PRODUCTS_PER_PAGE,
};

const defaultMarketplaceFilter: Paginated<IMarketplaceBuildFilter> = {
  ...defaultConnectionFilters,
  hasStock: false,
  noConnectedProducts: false,
  limit: PRODUCTS_PER_PAGE,
  shipsFrom: [],
  productInventory: PRODUCT_INVENTORY.SHOW_ALL,
};

const defaultMarketplaceProductViewFilter: Paginated<IMarketplaceImportFilter> = {
  ...defaultConnectionFilters,
  noConnectedProducts: false,
  properties: [PRODUCT_PROPERTIES.HIDE_WITHOUT_PHOTOS],
  productCategory: PRODUCT_CATEGORY.NONE,
  productInventory: PRODUCT_INVENTORY.SHOW_ALL,
  shipsFrom: [],
  limit: PRODUCTS_PER_PAGE,
};

const clearMarketplaceProductViewFilter: Paginated<IMarketplaceImportFilter> = {
  ...defaultMarketplaceProductViewFilter,
  properties: [],
  limit: PRODUCTS_PER_PAGE,
};

const initialState: IMarketplaceReducer = {
  options: {
    vendors: [],
    fulfilledBy: [],
    types: [],
    properties: [],
    shipsFrom: [],
  },
  productView: {
    productList: { productGroups: [], total: 0 },
    filter: defaultMarketplaceProductViewFilter,
    variants: {},
    fetching: false,
  },
  variantView: {
    variantList: { products: [], connectedProducts: [], total: 0 },
    filter: defaultMarketplaceFilter,
    retailerProductsFilters: defaultRetailerProductsFilters,
    fetching: false,
  },
  areProductsAddedToImportList: false,
};

export const marketplaceReducer = createReducer<IMarketplaceReducer, RootActions>(initialState)
  .handleAction(setMarketplaceProductListFetchingAction, (state, { payload }) => ({
    ...state,
    productView: {
      ...state.productView,
      fetching: payload,
    },
  }))
  .handleAction(setMarketplaceProductListAction, (state, { payload }) => ({
    ...state,
    productView: {
      ...state.productView,
      productList: payload,
    },
  }))
  .handleAction(setMarketplaceProductListPendingStatusAction, (state, { payload }) => ({
    ...state,
    productView: {
      ...state.productView,
      productList: {
        ...state.productView.productList,
        productGroups: state.productView.productList.productGroups.map((pG) => {
          if (pG.supplier === payload) {
            return {
              ...pG,
              accessStatus: MARKETPLACE_ACCESS_STATUS.REQUESTED,
            };
          }

          return pG;
        }),
      },
    },
  }))
  .handleAction(setMarketplaceVariantListFetchingAction, (state, { payload }) => ({
    ...state,
    variantView: {
      ...state.variantView,
      fetching: payload,
    },
  }))
  .handleAction(setMarketplaceVariantListAction, (state, { payload }) => ({
    ...state,
    variantView: {
      ...state.variantView,
      variantList: { ...state.variantView.variantList, ...payload },
    },
  }))
  .handleAction(setMarketplaceProductVariantsAction, (state, { payload }) => ({
    ...state,
    productView: {
      ...state.productView,
      variants: {
        ...state.productView.variants,
        [payload.productId]: payload.variants,
      },
    },
  }))
  .handleAction(setMarketplaceVariantListPendingStatusAction, (state, { payload }) => ({
    ...state,
    variantView: {
      ...state.variantView,
      variantList: {
        ...state.variantView.variantList,
        products: state.variantView.variantList.products.map((p) => {
          if (p.supplierId === payload) {
            return {
              ...p,
              accessStatus: MARKETPLACE_ACCESS_STATUS.REQUESTED,
            };
          }

          return p;
        }),
      },
    },
  }))
  .handleAction(setMarketplaceConnectedProductsAction, (state, { payload }) => ({
    ...state,
    variantView: {
      ...state.variantView,
      variantList: {
        ...state.variantView.variantList,
        connectedProducts: payload,
      },
    },
  }))
  .handleAction(decrementVariantConnectedProductsAction, (state, { payload: variantId }) => {
    const disconnectedVariant = state.variantView.variantList.products.find(
      (p) => p.id === variantId,
    );
    return disconnectedVariant
      ? {
          ...state,
          variantView: {
            ...state.variantView,
            variantList: {
              ...state.variantView.variantList,
              products: [
                ...state.variantView.variantList.products.map((p) => {
                  if (p.id === variantId)
                    return {
                      ...disconnectedVariant,
                      connectedProducts: disconnectedVariant.connectedProducts - 1,
                    };

                  return p;
                }),
              ],
            },
          },
        }
      : state;
  })
  .handleAction(removeDisconnectedProductsAction, (state, { payload }) => ({
    ...state,
    variantView: {
      ...state.variantView,
      variantList: {
        ...state.variantView.variantList,
        connectedProducts: state.variantView.variantList.connectedProducts?.filter(
          (p) => p.id !== payload.id,
        ),
      },
    },
  }))
  .handleAction(setMarketplaceFilterOptionsAction, (state, { payload }) => ({
    ...state,
    options: payload,
  }))
  .handleAction(showImportListProductsAddedToastAction, (state) => ({
    ...state,
    areProductsAddedToImportList: true,
  }))
  .handleAction(hideImportListProductsAddedToastAction, (state) => ({
    ...state,
    areProductsAddedToImportList: false,
  }))
  .handleAction(setMarketplaceProductListFiltersAction, (state, { payload }) => ({
    ...state,
    productView: {
      ...state.productView,
      filter: { ...state.productView.filter, ...payload, page: 0 },
    },
  }))
  .handleAction(resetMarketplaceProductListFiltersAction, (state) => ({
    ...state,
    productView: {
      ...state.productView,
      filter: defaultMarketplaceProductViewFilter,
    },
  }))
  .handleAction(clearAllMarketplaceProductListFiltersAction, (state) => ({
    ...state,
    productView: {
      ...state.productView,
      filter: clearMarketplaceProductViewFilter,
    },
  }))
  .handleAction(setMarketplaceProductListQueryFilterAction, (state, { payload }) => ({
    ...state,
    productView: {
      ...state.productView,
      filter: { ...state.productView.filter, ...payload, page: 0 },
    },
  }))
  .handleAction(setMarketplaceProductListVendorFilterAction, (state, { payload }) => ({
    ...state,
    productView: {
      ...state.productView,
      filter: { ...state.productView.filter, ...payload, page: 0 },
    },
  }))
  .handleAction(setMarketplaceProductListTypeFilterAction, (state, { payload }) => ({
    ...state,
    productView: {
      ...state.productView,
      filter: { ...state.productView.filter, ...payload, page: 0 },
    },
  }))
  .handleAction(setMarketplaceProductListPropertyFilterAction, (state, { payload }) => ({
    ...state,
    productView: {
      ...state.productView,
      filter: { ...state.productView.filter, ...payload, page: 0 },
    },
  }))
  .handleAction(setMarketplaceProductListSupplierFilterAction, (state, { payload }) => ({
    ...state,
    productView: {
      ...state.productView,
      filter: { ...state.productView.filter, ...payload, page: 0 },
    },
  }))
  .handleAction(setMarketplaceProductListConnectionFilterAction, (state, { payload }) => ({
    ...state,
    productView: {
      ...state.productView,
      filter: { ...state.productView.filter, ...payload, page: 0 },
    },
  }))
  .handleAction(setMarketplaceProductListCategoryFilterAction, (state, { payload }) => ({
    ...state,
    productView: {
      ...state.productView,
      filter: { ...state.productView.filter, ...payload, page: 0 },
    },
  }))
  .handleAction(setMarketplaceProductListInventoryFilterAction, (state, { payload }) => ({
    ...state,
    productView: {
      ...state.productView,
      filter: { ...state.productView.filter, ...payload, page: 0 },
    },
  }))
  .handleAction(setMarketplaceProductListShipsFromFilterAction, (state, { payload }) => ({
    ...state,
    productView: {
      ...state.productView,
      filter: { ...state.productView.filter, ...payload, page: 0 },
    },
  }))
  .handleAction(setMarketplaceProductListPageFilterAction, (state, { payload }) => ({
    ...state,
    productView: {
      ...state.productView,
      filter: { ...state.productView.filter, ...payload },
    },
  }))
  .handleAction(setMarketplaceProductListLimitFilterAction, (state, { payload }) => ({
    ...state,
    productView: {
      ...state.productView,
      filter: { ...state.productView.filter, ...payload },
    },
  }))
  .handleAction(setMarketplaceVariantListFiltersAction, (state, { payload }) => ({
    ...state,
    variantView: {
      ...state.variantView,
      filter: { ...state.variantView.filter, ...payload, page: 0 },
    },
  }))
  .handleAction(resetMarketplaceVariantListFiltersAction, (state) => ({
    ...state,
    variantView: {
      ...state.variantView,
      filter: defaultMarketplaceFilter,
    },
  }))
  .handleAction(setMarketplaceVariantListQueryFilterAction, (state, { payload }) => ({
    ...state,
    variantView: {
      ...state.variantView,
      filter: { ...state.variantView.filter, ...payload, page: 0 },
    },
  }))
  .handleAction(setMarketplaceVariantListVendorFilterAction, (state, { payload }) => ({
    ...state,
    variantView: {
      ...state.variantView,
      filter: { ...state.variantView.filter, ...payload, page: 0 },
    },
  }))
  .handleAction(setMarketplaceVariantListTypeFilterAction, (state, { payload }) => ({
    ...state,
    variantView: {
      ...state.variantView,
      filter: { ...state.variantView.filter, ...payload, page: 0 },
    },
  }))
  .handleAction(setMarketplaceVariantListStockFilterAction, (state, { payload }) => ({
    ...state,
    variantView: {
      ...state.variantView,
      filter: { ...state.variantView.filter, ...payload, page: 0 },
    },
  }))
  .handleAction(setMarketplaceVariantListSupplierFilterAction, (state, { payload }) => ({
    ...state,
    variantView: {
      ...state.variantView,
      filter: { ...state.variantView.filter, ...payload, page: 0 },
    },
  }))
  .handleAction(setMarketplaceVariantListConnectionFilterAction, (state, { payload }) => ({
    ...state,
    variantView: {
      ...state.variantView,
      filter: { ...state.variantView.filter, ...payload, page: 0 },
    },
  }))
  .handleAction(setMarketplaceVariantListInventoryFilterAction, (state, { payload }) => ({
    ...state,
    variantView: {
      ...state.variantView,
      filter: { ...state.variantView.filter, ...payload, page: 0 },
    },
  }))
  .handleAction(setMarketplaceVariantListPageFilterAction, (state, { payload }) => ({
    ...state,
    variantView: {
      ...state.variantView,
      filter: { ...state.variantView.filter, ...payload },
    },
  }))
  .handleAction(setMarketplaceVariantListLimitFilterAction, (state, { payload }) => ({
    ...state,
    variantView: {
      ...state.variantView,
      filter: { ...state.variantView.filter, ...payload },
    },
  }))
  .handleAction(setMarketplaceRetailerProductsFiltersAction, (state, { payload }) => ({
    ...state,
    variantView: {
      ...state.variantView,
      retailerProductsFilters: { ...state.variantView.filter, ...payload, page: 0 },
    },
  }))
  .handleAction(resetMarketplaceRetailerProductsFiltersAction, (state) => ({
    ...state,
    variantView: {
      ...state.variantView,
      retailerProductsFilters: defaultConnectionFilters,
    },
  }))
  .handleAction(setMarketplaceRetailerProductsQueryFilterAction, (state, { payload }) => ({
    ...state,
    variantView: {
      ...state.variantView,
      retailerProductsFilters: {
        ...state.variantView.retailerProductsFilters,
        ...payload,
        page: 0,
      },
    },
  }))
  .handleAction(setMarketplaceRetailerProductsVendorFilterAction, (state, { payload }) => ({
    ...state,
    variantView: {
      ...state.variantView,
      retailerProductsFilters: {
        ...state.variantView.retailerProductsFilters,
        ...payload,
        page: 0,
      },
    },
  }))
  .handleAction(setMarketplaceRetailerProductsTypeFilterAction, (state, { payload }) => ({
    ...state,
    variantView: {
      ...state.variantView,
      retailerProductsFilters: {
        ...state.variantView.retailerProductsFilters,
        ...payload,
        page: 0,
      },
    },
  }))
  .handleAction(setMarketplaceRetailerProductsSupplierFilterAction, (state, { payload }) => ({
    ...state,
    variantView: {
      ...state.variantView,
      retailerProductsFilters: {
        ...state.variantView.retailerProductsFilters,
        ...payload,
        page: 0,
      },
    },
  }))
  .handleAction(setMarketplaceRetailerProductsConnectionFilterAction, (state, { payload }) => ({
    ...state,
    variantView: {
      ...state.variantView,
      retailerProductsFilters: {
        ...state.variantView.retailerProductsFilters,
        ...payload,
        page: 0,
      },
    },
  }))
  .handleAction(setMarketplaceRetailerProductsPageFilterAction, (state, { payload }) => ({
    ...state,
    variantView: {
      ...state.variantView,
      retailerProductsFilters: { ...state.variantView.retailerProductsFilters, ...payload },
    },
  }))
  .handleAction(setMarketplaceRetailerProductsLimitFilterAction, (state, { payload }) => ({
    ...state,
    variantView: {
      ...state.variantView,
      retailerProductsFilters: { ...state.variantView.retailerProductsFilters, ...payload },
    },
  }));
