import React, { useState } from 'react';
import Flag from 'react-flagkit';
import { ImageIcon } from '@shopify/polaris-icons';
import { CheckboxIcon } from '@shopify/polaris-icons';
import { NoteIcon } from '@shopify/polaris-icons';
import { XCircleIcon } from '@shopify/polaris-icons';
import { DeliveryFilledIcon, CartSaleIcon, ChevronDownIcon } from '@shopify/polaris-icons';
import { AppCard } from '../../structure/card/card';
import { Card, Stack, Tag } from '@shopify/polaris';
import { AppCheckbox } from '../../checkbox/checkbox';
import { AppTooltip } from '../../overlays/tooltip/tooltip';
import { AppIcon } from '../../icon/icon';
import { AppHeading } from '../../text/heading/heading';
import { AppTextStyle } from '../../text/text-style/TextStyle';
import { IProduct } from '../../../interfaces/IProduct';
import { PRODUCT_CATEGORY } from '../../../constants/product.constants';
import { POSTAGE_SETUP } from '../../../../shopify-supplier/constants/preferences.constants';
import { AppLink } from '../../link/link';
import { withCurrency } from '../../../helpers/currency.helper';
import { AVAILABLE_RATES } from '../../../../shopify-supplier/interfaces/IPreferences';
import { ShippingTooltip } from '../../shipping-tooltip/shipping-tooltip';
import { isProductApproved } from '../../../helpers/product-request.helper';
import { RequestApprovalWrapper } from '../../request-approval/request-approval-wrapper/request-approval-wrapper';

import './product-card.scss';

interface ProductCardProps {
  product: IProduct;
  selectedProducts: string[];
  onSelectionHandler: (checked: boolean, productId: string) => void;
  handleCreateRequest: (supplierId: string) => void;
}

export function ProductCard({
  product,
  selectedProducts,
  onSelectionHandler,
  handleCreateRequest,
}: ProductCardProps) {
  const [standartDomesticShipping] = useState(
    product.shipping?.shippingTypesMapping.find((sTM) => sTM.shippingType === 'Standard Domestic'),
  );
  let shippingPrice: string;
  if (
    standartDomesticShipping?.flatRateCost &&
    standartDomesticShipping?.availableRates === AVAILABLE_RATES.FLAT_RATE
  )
    shippingPrice = withCurrency(standartDomesticShipping?.flatRateCost);
  else
    shippingPrice =
      standartDomesticShipping?.availableRates === AVAILABLE_RATES.CARRIER_RATES
        ? 'Live Rates'
        : 'Free';
  return (
    <div className="product-card">
      <AppCard key={product.id}>
        <div className="product-card__header">
          <div className="product-card__header__options">
            <Stack>
              <Stack.Item fill>
                <AppCheckbox
                  label={'product'}
                  disabled={!isProductApproved(product.accessStatus)}
                  labelHidden
                  checked={selectedProducts.includes(product.id)}
                  onChange={(checked) => onSelectionHandler(checked, product.id)}
                />
              </Stack.Item>
              <Stack.Item>
                <div className="product-card__header__options__icons">
                  {product.freeShipping && (
                    <AppTooltip content={'Free Shipping'} preferredPosition="above">
                      <AppIcon source={DeliveryFilledIcon} color="subdued" />
                    </AppTooltip>
                  )}
                  {product.productCategory !== PRODUCT_CATEGORY.CANNABIS && (
                    <AppTooltip content={'Drop Shipping'} preferredPosition="above">
                      <AppIcon source={CartSaleIcon} color="subdued" />
                    </AppTooltip>
                  )}
                  {product.postageSetup === POSTAGE_SETUP.CROWDSHIP && (
                    <AppTooltip
                      content={
                        <>
                          <AppTextStyle variation="strong">Branded Packing Slips</AppTextStyle>
                          <p>
                            <AppLink url="/settings?tab=company">Upload a logo</AppLink> to ensure a
                            branded packing slip is included with your orders.
                          </p>
                        </>
                      }
                      preferredPosition="above"
                    >
                      <AppIcon source={NoteIcon} color="subdued" />
                    </AppTooltip>
                  )}
                </div>
              </Stack.Item>
            </Stack>
          </div>
          {product.image ? (
            <figure className="product-card__image">
              <img alt="Product" width="100%" height="100%" src={product.image} />
            </figure>
          ) : (
            <div className="product-card__no-image">
              <AppIcon source={ImageIcon} />
            </div>
          )}
        </div>

        <Card.Section>
          <AppHeading>{product.title}</AppHeading>
          <div className="product-card__brand">
            <AppTextStyle variation="subdued">
              <AppTextStyle variation="strong">Brand: </AppTextStyle>
              {product.vendor}
            </AppTextStyle>
          </div>
          <div className="product-card__supplier">
            <AppTextStyle variation="subdued">
              <AppTextStyle variation="strong">Sold By: </AppTextStyle>
              {product.supplierName}
            </AppTextStyle>
          </div>
          <div className="product-card__shipping">
            <div>
              <AppTextStyle variation="subdued">
                <AppTextStyle variation="strong">Shipping:&nbsp;</AppTextStyle>
                {shippingPrice}&nbsp;
                <Flag country={product.location.countryCode} size={20} />
                &nbsp;
                {standartDomesticShipping?.startDeliveryTime || '5'} -{' '}
                {standartDomesticShipping?.finishDeliveryTime || '9'}
                {' days '}
                <ShippingTooltip
                  shippingTypesMapping={product.shipping.shippingTypesMapping}
                  location={product.location}
                  variants={product.variants}
                >
                  <AppIcon source={ChevronDownIcon} color="subdued" />
                </ShippingTooltip>
              </AppTextStyle>
            </div>
          </div>
          <Stack distribution="equalSpacing">
            <AppTextStyle variation="subdued">
              {product.variationsNumber !== 1 && `${product.variationsNumber} Variants`}
            </AppTextStyle>
            <div className="product-card__stock-icon">
              {product.quantityAvailable > 0 ||
              product.variants.some(
                (v) => v.tracked === false || v.inventoryPolicy === 'continue',
              ) ? (
                <>
                  <AppIcon source={CheckboxIcon} color="base" />{' '}
                  <AppTextStyle variation="subdued">In stock</AppTextStyle>
                </>
              ) : (
                <>
                  <AppIcon source={XCircleIcon} color="base" />
                  <AppTextStyle variation="subdued">Sold out</AppTextStyle>
                </>
              )}
            </div>
          </Stack>
          <div className="product-card__prices">
            <Stack spacing="extraTight" distribution="center" alignment="fill" wrap={false}>
              <Tag>
                <AppTextStyle variation="strong">Cost:</AppTextStyle>
                <RequestApprovalWrapper
                  supplierId={product.supplier}
                  accessStatus={product.accessStatus}
                  handleCreateRequest={handleCreateRequest}
                >
                  <div className="price--yellow">{`${withCurrency(product.price)}${
                    product.isPriceForRetailersVaries ? '+' : ''
                  }`}</div>
                </RequestApprovalWrapper>
              </Tag>
              <Tag>
                <AppTextStyle variation="strong">SRP:</AppTextStyle>
                <div className="price--blue">{`${withCurrency(product.MSRP)}`}</div>
              </Tag>
            </Stack>
          </div>
          <Stack distribution="center">
            <AppLink url={`marketplace/${product.id}`} removeUnderline monochrome>
              View Details
            </AppLink>
          </Stack>
        </Card.Section>
      </AppCard>
    </div>
  );
}
